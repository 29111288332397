body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.purple-gradient {
  background: #a8439f;

  background: linear-gradient(
    90deg,
    hsla(305, 43%, 46%, 1) 0%,
    hsla(301, 73%, 81%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(305, 43%, 46%, 1) 0%,
    hsla(301, 73%, 81%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(305, 43%, 46%, 1) 0%,
    hsla(301, 73%, 81%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#AA43A2", endColorstr="#F2ADF1", GradientType=1 );
}
